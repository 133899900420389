import { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SoftwareBanner from "../components/SoftwareComponents/SoftwareBanner";
import SoftwareSolutions from "../components/SoftwareComponents/SoftwareSolutions.jsx";
import SoftwareCards from "../components/SoftwareComponents/SoftwareCards";
import SendMsg from "../components/sendmsg/SendMsg";

function SoftwareDevelopment() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
}, []);
  return (
    <>
      <Navbar />
      <SoftwareBanner />
      <SoftwareSolutions />
      <SoftwareCards />
      <SendMsg />
      <Footer />
    </>
  );
}
export default SoftwareDevelopment;
