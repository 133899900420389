import React from "react";
import { Link } from "react-router-dom";

function HomeSolutions() {
  return (
    <div className="section-container home-solutions gray-background">
      {/* <div className="hs-title text-center py-5">Our Solutions</div> */}
      <h2 className="section-title">Our Solutions</h2>
      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-4  p-3">
          {" "}
          {/* Adjusted columns for tablet */}
          <div className="d-flex flex-column align-items-center align-sm-start">
            <img
              src="../assets/PointScreen.png"
              alt="pointing"
              className="img-fluid rounded"
            />
            <div className="h3 text-center text-sm-start mt-3">
              Software Development
            </div>
          </div>
          <div className="hs-content grey-text mt-3">
            Our team of skilled developers leverages the latest technologies and
            industry best practices to create intuitive, secure, and
            user-friendly software applications.
          </div>
          <br />
          <br />
          <Link to="/software-development" className="btn capabilities-btn">
            VIEW CAPABILITIES
          </Link>
        </div>

        <div className="col-md-1"></div>

        <div className="col-sm-6 col-md-4 p-3">
          {" "}
          {/* Adjusted columns for tablet */}
          <div className="d-flex flex-column align-items-center align-sm-start">
            <img src="../assets/Nurse.png" alt="nurse" className="img-fluid" />
            <div className="h3 text-center text-sm-start mt-3">
              Healthcare Staffing
            </div>
          </div>
          <div className="hs-content grey-text mt-3">
            Unique partnerships to provide temporary staff to cover seasonal
            demands or permanent placements for long-term support, we have the
            expertise to find the right fit for your organization.
          </div>
          <br />
          <br />
          <Link to="/healthcare-staffing" className="btn capabilities-btn">
            VIEW CAPABILITIES
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeSolutions;
