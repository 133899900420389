import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-lg-4">
            <img
              src="../assets/footer-logo.svg"
              alt="footer-logo"
              className="footer-logo"
            />
          </div>
          <div className="middle-section col-lg-4 align-self-center">
            <div className="phone row justify-content-evenly">
              <p className="col-lg-12 col-xl-6">
                <img
                  src="../assets/footer-phone-icon.svg"
                  alt="footer-logo"
                  className="phone-icon"
                />
                P: 985.266.3268
              </p>
              <p className="col-lg-12 col-xl-6">
                <img
                  src="../assets/footer-phone-icon.svg"
                  alt="footer-logo"
                  className="phone-icon"
                />
                C: 985.791.9506
              </p>
            </div>
            <div className="d-flex justify-content-evenly">
              <Link to="/contactus" className="linkStyle">
                Contact Us
              </Link>
              <Link to="/" className="linkStyle">
                Capabilities
              </Link>
            </div>
            <p className="copyright">
              © 2023 Faith Lake Solutions. All Rights Reserved
            </p>
          </div>
          <div className="bottom-section col-lg-4">
            <img src="../assets/footer-cert.svg" alt="footer-logo" />
            <p className="cage-code">
              UEI #HSGCNJJJZ343 <br /> CAGE CODE is 9BEB2
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
