import { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import HomeBanner from "../components/HomeComponents/HomeBanner";
import HomeNavButtons from "../components/HomeComponents/HomeNavButtons";
import HomeMiddleBlock from "../components/HomeComponents/HomeMiddleBlock";
import HomeSolutions from "../components/HomeComponents/HomeSolutions";
import Footer from "../components/footer/Footer";
import SendMsg from "../components/sendmsg/SendMsg";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
}, []);
  return (
    <>
      <Navbar />
      <HomeBanner />
      <HomeNavButtons />
      <HomeMiddleBlock />
      <HomeSolutions />
      <SendMsg />
      <Footer />
    </>
  );
}

export default Home;
