function SoftwareCards() {
  const data = [
    {
      title: "KUBERNETES CERTIFIED",
      description:
        "Automate computer application deployment with confidence by migrating to Kubernetes.",
      imgUrl: "../assets/Kubernetes.png",
      imgAlt: "Kubernetes",
    },
    {
      title: "INFRASTRUCTURE-AS-CODE & CONFIGRATION-AS-CODE",
      description:
        "Our services provide you the ease in managing all your servers and configuration. Tools like Terraform, Ansible, Puppet, Chef and more, can transform your infrastructure through automation and continuous delivery.",
      imgUrl: "../assets/iac_cac.png",
      imgAlt: "IAC",
    },
    {
      title: "CONTINUOUS INTEGRATION/ CONTINUOUS DEPLOYMENT",
      description:
        "Ship software quickly and efficiently, getting products to market faster than ever before by using Continuous Integration/ Continuous Deployment.",
      imgUrl: "../assets/ci_cd.png",
      imgAlt: "CI/CD",
    },
    {
      title: "SERVERLESS ARCHITECTURES",
      description:
        "Build and run applications and services without having to manage infrastructure",
      imgUrl: "../assets/ci_cd.png",
      imgAlt: "CI/CD",
    },
    {
      title: "CLOUD, ON-PREM, AND HYBRID INFRASTRUCTURE",
      description:
        "With strong capabilities in on-prem, cloud and hybrid infrastructure, we are here to help your organization. (AWS, Azure, GCP, VMWare)",
      imgUrl: "../assets/Cloud.png",
      imgAlt: "Cloud",
    },
    {
      title: "CYBER",
      description:
        "Protect your data and your reputation with state-of-the-art cyber solutions to proactively defend your organization. We focus on reducing, securing, and maintaining valuable assets.",
      imgUrl: "../assets/cyber.png",
      imgAlt: "Cyber",
    },
  ];

  const cards = data.map((card, index) => {
    return (
      <div className="col-sm-12 col-lg-6 col-xl-4 p-4">
        <div className="card sw-card">
          <img
            src={card.imgUrl}
            class="card-img-top"
            alt={card.imgAlt}
            className="sw-card-img"
          />
          <div className="card-body">
            <h5
              className={`${
                index === 1 || index === 2 || index === 4
                  ? "small-title"
                  : "sw-card-title"
              }`}
            >
              {card.title}
            </h5>
            <p className="sw-card-text">{card.description}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="row">{cards}</div>
    </div>
  );
}

export default SoftwareCards;
