import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import "./App.css";
import SoftwareDevelopment from "./Pages/SoftwareDevelopment";
import HealthcareStaffing from "./Pages/HealthcareStaffing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/software-development" element={<SoftwareDevelopment />} />
      <Route path="/healthcare-staffing" element={<HealthcareStaffing />} />
      <Route path="/contactus" element={<ContactUs />} />
    </Routes>
  );
}

export default App;
