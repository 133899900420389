/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="my-3">
          <Link className="navbar-brand" to="/">
            <img
              src="../assets/flsLogo.svg"
              alt="logo"
              className="navbar-logo"
            />
            <div className="navbar-title navbar-text-blue">
              FAITH LAKE SOLUTIONS
            </div>
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="dropdown-toggle navbar-link-text"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Capabilities
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/software-development">
                    Software Development
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/healthcare-staffing">
                    Healthcare Staffing
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="navbar-link-text" to="/contactus">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
