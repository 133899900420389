import { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import HealthcareBanner from "../components/HealthcareComponents/HealthcareBanner";
import HealthcareSolutions from "../components/HealthcareComponents/HealthcareSolutions";
import HealthcareCards from "../components/HealthcareComponents/HealthcareCards";
import SendMsg from "../components/sendmsg/SendMsg";

function HealthcareStaffing() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
}, []);

  return (
    <>
      <Navbar />
      <HealthcareBanner />
      <HealthcareSolutions />
      <HealthcareCards />
      <SendMsg />
      <Footer />
    </>
  );
}

export default HealthcareStaffing;
