// import "./SendMsg.css";
// import emailjs from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

function SendMsg() {
  const form = useRef();
  const SERVICE_ID = "service_0ax7197";
  const TEMPLATE_ID = "contact_template";
  const PUBLIC_KEY = "jPMb642q-xGsLOZAu";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        console.log(`${result.text}: Email was sent successfully`);
        form.current.reset();
      },
      (error) => {
        console.log(`${error.text}: Sorry there was an issue`);
      }
    );
  };
  return (
    <>
      <div className="section-container send-msg">
        <h2 className="section-title">Send us a Message</h2>
        <form
          ref={form}
          className="row justify-content-center"
          onSubmit={sendEmail}
        >
          <div className="col-md-4">
            <div>
              <div className="mb-4">
                <label htmlFor="fname" className="form-label">
                  FIRST NAME
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder="John"
                  name="fname"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="lname" className="form-label">
                  LAST NAME
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lname"
                  placeholder="Doe"
                  name="lname"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  EMAIL ADDRESS
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="name"
                  placeholder="name@example.com"
                  name="user_email"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="form-label">
                  PHONE
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  // pattern="^\d{3}-\d{3}-\d{4}$"
                  placeholder="210-123-4567"
                  name="user_phone"
                  required
                />
              </div>
            </div>
          </div>
          <div className="contact-btn">
            <button type="submit">CONTACT US</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SendMsg;
